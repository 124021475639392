import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

import "./App.css";
import "./boxicons.css";

const IN_DEVELOPMENT: NETWORK[] = [
  {
    label: "Polkadot",
    id: "polkadot",
  },
  {
    label: "Algorand",
    id: "algorand",
  },
  {
    label: "Celo",
    id: "celo",
  },
  {
    label: "Eth 2.0",
    id: "ethereum_2",
  },
];

const IN_DEVELOPMENT_NATIVE: NETWORK[] = [
  {
    label: "Bitcoin (Native API)",
    id: "bitcoin",
  },
  {
    label: "Ethereum (Native API)",
    id: "ethereum",
  },
  {
    label: "Litecoin (Native API)",
    id: "litecoin",
  },
  {
    label: "Stellar (Native API)",
    id: "stellar",
  },
];

type NETWORK = {
  label: string;
  id: string;
};

type MESSAGE = {
  detail: {
    currency?: {
      type: string;
      symbol: string;
      decimals: number;
    };
    value?: number;
    total_in?: number;
    total_out?: number;
    from?: string;
    to?: string;
    inputs?: {
      address: string;
    }[];
    outputs?: {
      address: string;
    }[];
  };
};

type DISPLAY_MESSAGE = {
  id: string;
  date: string;
  amount: string;
  from?: string;
  to?: string;
  fee: string;
};

const NETWORKS: NETWORK[] = [
  {
    label: "Bitcoin",
    id: "bitcoin",
  },
  {
    label: "Ethereum",
    id: "ethereum",
  },
  {
    label: "Stellar",
    id: "stellar",
  },
  {
    label: "Ripple",
    id: "xrp",
  },
];

// in bitcoin the API doesn't respond with a fee message so for standardization we are stitching that transaction
const getFeeBitcoin = (message: MESSAGE) => {
  return getAmount({
    detail: {
      ...message.detail,
      value: message.detail.total_in - message.detail.total_out,
    },
  });
};

const getAmount = (message: MESSAGE) =>
  message?.detail?.currency
    ? message?.detail?.currency.type === "smart_token" // ERC20 tokens don't have currency information attached
      ? "ERC20"
      : `${(
          (message.detail.value || message.detail.total_in || 0) /
          Math.pow(10, message.detail.currency.decimals)
        ).toFixed(6)}${message.detail.currency.symbol}`
    : "";

const parseMultiTransferAddresses = (
  addresses: { address: string }[] | undefined
) => addresses?.map(({ address }) => address).join(", ");

const convertApiToDisplayTxs: (
  transaction: any,
  network: string
) => DISPLAY_MESSAGE[] = ({ id, date, operations }: any, network: string) => {
  let filteredMessages: [string, any][] = Object.entries(operations).filter(
    ([key]) => key !== "fee"
  );
  // if there is no message apart from the fee message, we assume, that the messages didn't get parsed correctly
  if (filteredMessages.length === 0) {
    filteredMessages = [
      [
        "unknown",
        {
          type: "unknown",
          detail: {},
        },
      ],
    ];
  }
  return filteredMessages.map(
    ([, message]: [string, MESSAGE], messageIndex) => ({
      // in bitcoin the API doesn't respond with a fee message so for standardization we are stitching that transaction
      fee:
        network === "bitcoin"
          ? getFeeBitcoin(filteredMessages[0][1])
          : getAmount(operations.fee),
      ...message,
      messageIndex,
      id,
      date: new Date(date).toLocaleTimeString("en-US"),
      amount: getAmount(message),
      from:
        message.detail.from ||
        parseMultiTransferAddresses(message.detail.inputs),
      to:
        message.detail.to ||
        parseMultiTransferAddresses(message.detail.outputs),
    })
  );
};

const App = () => {
  const [error, setError] = React.useState<Error | undefined>();
  const [selectedNetwork, setNetwork] = React.useState<NETWORK | undefined>();
  const [displayTxs, setTransactions] = React.useState<DISPLAY_MESSAGE[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [websocket, setWebsocket] = React.useState<WebSocket | undefined>();
  const [ping, setPingCycle] = React.useState<NodeJS.Timeout | undefined>();

  const switchNetwork = (network: NETWORK) => {
    if (network === selectedNetwork) return;

    setNetwork(network);
    // clear old networks txs
    setTransactions([]);
    // assume no intent when user switches network
    setError(undefined);
    setLoading(true);
    try {
      // close websocket of old network to not open too many websockets
      if (websocket) {
        websocket.onclose = () => {};
        websocket.close();
        ping && clearInterval(ping);
      }
      const currentWebsocket = new WebSocket(
        `wss://demo.stg.ubiquity.api.blockdaemon.com/updates/${network.id}/mainnet`
      );
      currentWebsocket.onmessage = (event) => {
        // assume errors are cleared when we get a message
        setError(undefined);
        setLoading(false);
        const messages = convertApiToDisplayTxs(
          JSON.parse(event.data),
          network.id
        );

        /* React hooks have an issue with accessing current state in loops https://stackoverflow.com/a/63039797 */
        let currentDisplayTxs: DISPLAY_MESSAGE[] = [];
        setTransactions((displayTxs) => {
          currentDisplayTxs = displayTxs;
          return displayTxs;
        });

        // push new txs and only show 10
        setTransactions(messages.concat(currentDisplayTxs).slice(0, 10));
      };
      currentWebsocket.onclose = (event) => {
        console.log(event);
        setError(new Error(event.reason || "Connection failed"));
      };
      setWebsocket(currentWebsocket);

      // keep the websocket connection alive by sending an alive message
      setPingCycle(setInterval(() => currentWebsocket.send("PING"), 5000));
    } catch (err) {
      setError(err);
    }
  };
  if (!selectedNetwork) switchNetwork(NETWORKS[0]);

  const shorten = (hash: string) =>
    hash
      ? hash.substr(0, 4) + "..." + hash.substr(hash.length - 4, hash.length)
      : "";

  return (
    <div className="app">
      <div className="hero">
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="https://blockdaemon.com">
              <img src="../white-logo.svg" alt="" />
            </a>
          </li>
        </ul>
        <div className="container">
          <img className="logo" src="../ubiquity-logo.svg" alt="" />
          <h1>Ubiquity API Demo</h1>
          <p>
            Ubiquity, Blockdaemon’s indexed API, generalizes blockchain content
            into a common format making it easy for reading blockchain data
            across multiple protocols.
          </p>
          <p>
            <a
              href="https://app.blockdaemon.com/ubiquity"
              className="btn btn-primary btn-lg"
            >
              Get Started
            </a>
            <a
              href="https://app.blockdaemon.com/docs"
              className="btn btn-success btn-lg"
            >
              Learn More
            </a>
          </p>
        </div>
      </div>
      <div className="container">
        <h2>Getting Started</h2>
        <p className="description">
          Click on a protocol to change the API request and real-time stream of
          transactions displayed in the table&nbsp;below.
        </p>
        <p className="protocols protocol-selector">
          {NETWORKS.map((network) => (
            <span
              key={network.id}
              className={`protocol ${
                network === selectedNetwork ? "active" : ""
              }`}
              onClick={() => switchNetwork(network)}
            >
              <img src={`../${network.id}.svg`} alt="" />
            </span>
          ))}
        </p>
        {selectedNetwork && (
          <div className="snippet">
            <SyntaxHighlighter language="shell">
              {`curl --request GET
--url 'https://ubiquity.api.blockdaemon.com/v2/${selectedNetwork.id}/mainnet/txs'
--header 'Authorization: Bearer YOUR_TOKEN'`}
            </SyntaxHighlighter>
          </div>
        )}
        <div className="loader">
          {error && <p className="text-danger">{error?.message}</p>}
          {loading ? (
            <div
              className="d-flex justify-content-center"
              style={{
                paddingTop: "3rem",
              }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  <th>Tx ID</th>
                  <th>Time</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Amount</th>
                  <th>Fee</th>
                </tr>
              </thead>
              <tbody>
                {displayTxs.map((message: any) => (
                  <tr key={message.id + message.messageIndex}>
                    <td className="text-shorten">{shorten(message.id)}</td>
                    <td>{message.date}</td>
                    <td className="text-shorten">
                      {shorten(
                        message.detail?.from ||
                          parseMultiTransferAddresses(message.detail?.inputs)
                      )}
                    </td>
                    <td className="text-shorten">
                      {shorten(
                        message.detail?.to ||
                          parseMultiTransferAddresses(message.detail?.outputs)
                      )}
                    </td>
                    <td>{message.amount}</td>
                    <td>{message.fee}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {selectedNetwork && (
        <div className="container">
          <h2>Get Connected</h2>
          <p className="description">
            The Ubiquity API can be called using tools like curl, wget, wsdump,
            and Postman. Just{" "}
            <a href="https://app.blockdaemon.com/register">sign up</a>, create a
            Blockdaemon API token, and connect via WebSocket or HTTP.
          </p>
          <div className="snippet">
            <h3>WebSockets</h3>
            <SyntaxHighlighter language="shell">
              {`wsdump
--headers 'Authorization: Bearer YOUR_TOKEN'
wss://ubiquity.api.blockdaemon.com/v2/${selectedNetwork.id}/mainnet/websocket/`}
            </SyntaxHighlighter>
          </div>

          <div className="snippet">
            <h3>HTTP Requests</h3>
            <p className="description">
              Ubiquity also offers an indexed API for easily accessing
              historical data from Ubiquity-supported protocols.
            </p>
            <h4>Get Accounts</h4>
            <SyntaxHighlighter language="shell">
              {`curl --request GET
--url 'https://ubiquity.api.blockdaemon.com/v2/${selectedNetwork.id}/mainnet/accounts'
--header 'Authorization: Bearer YOUR_TOKEN'`}
            </SyntaxHighlighter>
          </div>
          <div className="snippet">
            <h4>Get Transactions</h4>
            <SyntaxHighlighter language="shell">
              {`curl --request GET
--url 'https://ubiquity.api.blockdaemon.com/v2/${selectedNetwork.id}/mainnet/txs'
--header 'Authorization: Bearer YOUR_TOKEN'`}
            </SyntaxHighlighter>
          </div>
        </div>
      )}
      <div className="container in-development">
        <h2>In Progress</h2>
        <div className="protocols">
          {IN_DEVELOPMENT.map((network) => (
            <span className="protocol no-hover">
              <img src={`../${network.id}.svg`} alt="" />
            </span>
          ))}
        </div>
        <h3>Native APIs</h3>
        <div className="protocols">
          {IN_DEVELOPMENT_NATIVE.map((network) => (
            <span className="protocol no-hover">
              <img src={`../${network.id}.svg`} alt="" />
            </span>
          ))}
        </div>
      </div>
      <div className="section">
        <div className="content">
          <h1>Ubiquity API</h1>
          <p>
            Access blockchain data via HTTPS or WebSockets using a single
            streamlined command set for Bitcoin, Ethereum, Stellar, and XRP
            (with more on the way).
          </p>
          <p>
            <a
              href="https://app.blockdaemon.com/ubiquity"
              className="btn btn-primary btn-lg"
            >
              Get Started
            </a>
            <a
              href="https://app.blockdaemon.com/docs"
              className="btn btn-success btn-lg"
            >
              Learn More
            </a>
          </p>
          <p className="footer">
            Have additional questions? Email us at{" "}
            <a href="mailto:hello@blockdaemon.com">hello@blockdaemon.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default App;
